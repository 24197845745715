import { render, staticRenderFns } from "./gdklsf.vue?vue&type=template&id=64f8c356&scoped=true"
import script from "./gdklsf.vue?vue&type=script&lang=js"
export * from "./gdklsf.vue?vue&type=script&lang=js"
import style0 from "./gdklsf.vue?vue&type=style&index=0&id=64f8c356&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f8c356",
  null
  
)

export default component.exports